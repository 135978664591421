.custom-table.msg-table .ant-table {
  max-height: calc(var(--content-height) - 140px);
}

.chcframe, .chcframe-outer {
  height: calc(var(--content-height) - 100px);
  background-color: white;
}

.chcframe-outer{
  position: relative;
  padding-left: 10px;
}

.chcframe-outer:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 240px);
  margin-right: 15px;
  height: 60px;
  background-color: white;
}

.chcframe-outer.chcframe-mac:after {
  content: '';
  width: calc(100% - 260px);
}

@media screen and (max-width: 791px) {
  .chcframe-outer:before {
    content: '';
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;
    margin-right: 15px;
    height: 35px;
    background-color: white;
  }
}

@media screen and (max-width: 424px) {
  .chcframe-outer:before {
    content: '';
    height: 70px;
  }
}

@media screen and (max-width: 305px) {
  .chcframe-outer:before {
    content: '';
    height: 102px;
  }
}