body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: 'Nunito', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ========== font-family =========== */
/* ========== global css =========== */
/* ========== padding & margin =========== */
/* ========== colors =========== */
/* ========== Text alignment =========== */
/* ========== content-alignment =========== */
/* ========== width & height =========== */
/* ========== position =========== */
/* ========== wrap & overflow =========== */
/* ========== font-size =========== */
/* ========== typography =========== */
/* ========== components =========== */
/* ========== table =========== */
/* ========== input =========== */
/* ========== disabled input =========== */
/* ========== button =========== */
/* ========== disabled button =========== */
/* ========== modal =========== */
/* ========== pop confirm button  =========== */
/* ========== badge & hr  =========== */
/* ========== scroll =========== */
/* ========== primary color =========== */
/* ========== custom search =========== */


/* ========== font-family =========== */

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-SemiBold.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-Bold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: url(../src/assets/fonts/Nunito-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

/* ========== global css =========== */
:root {
  --primaryblue: #0a1227;
  --primeblue_op: #0a1227e0;
  /* with opacity */
  --secondaryblue: #7d9bc1;
  --secondarygrey: #f2f2f2;
  --greyText: #BFC0BF;
  --redText: #D02139;
  --backgroundColor: #d7d7d7;
  --cardbkgcolor: #f2f2f2;
  --headerheight: 86px;
  --disabled: #e8e8e8;
  /* --disabled: #d1d1d1; */
  --success: #629d03;
  --danger: #D02139;
  --warning: #eaaa00;
  --brown: #7B3F00;

  /* table var */
  --tableheader: #d7d7d7;
  --tb-border-radius: 5px;
  --tb-border: #d7d7d7;
  --tblfs: 0.9rem;

  /* input */
  --label: #333333;
  --inputheight: 28px;
  --ipfs: 0.9rem;

  /* margin*/
  --bodymargin: 24px;
  --content-height: calc(100vh - var(--headerheight) - 73px);
  --modalborderradius: 10px;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  min-height: 100vh;
  font-size: 13px;
}

.content {
  margin: var(--bodymargin);
  min-height: calc(100vh - var(--headerheight) - 73px) !important;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

/* ========== padding & margin =========== */
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.mt-5px {
  margin-top: 5px;
}

.mr-neg24,
.mr-neg24.ant-row {
  margin-right: -24px !important;
}

.mt-neg-24 {
  margin-top: -24px !important;
}

.mt-neg-1 {
  margin-top: -1px;
}

.mb-24px {
  margin-bottom: 24px !important;
}

/* ========== colors =========== */
.link-color {
  color: var(--redText);
  text-decoration: underline;
}

.color-initial {
  color: initial;
}

.transparent {
  color: transparent !important;
}

.color-red {
  color: var(--redText);
}

.color-white {
  color: white;
}

.color-pblue {
  color: var(--primaryblue);
}


/* ========== Text alignment =========== */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

th.hd-txt-center {
  text-align: center !important;
}

/* ========== content-alignment =========== */
.d-none {
  display: none;
}

.d-block {
  display: block !important;
}

.d-inlineblock {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.fv-middle {
  display: flex !important;
  align-items: center;
}

.f-middle {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.f-spacebtw {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.f-column {
  display: flex !important;
  flex-direction: column;
}

.f-row {
  display: flex !important;
  flex-direction: row;
}

.f-align-end {
  display: flex !important;
  align-items: flex-end;
}

.f-justify-end {
  display: flex !important;
  justify-content: flex-end;
}

.f-align-start{
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-start;
}

.f-wrap {
  flex-wrap: wrap;
}

.gap-10 {
  gap: 10px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex {
  flex: 1 0 auto;
}

.flex-auto {
  flex: auto;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-6 {
  flex: 6;
}

/* ========== width & height =========== */
.w-100 {
  width: 100% !important;
}

.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.w-50px {
  width: 50px !important;
}

.w-35px {
  width: 35px !important;
}

.w-35px.ant-input-number {
  width: 35px !important;
}

.min-width-338 {
  min-width: 338px;
}

.min-width-320 {
  min-width: 320px;
}

.min-width-200 {
  min-width: 200px !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.min-width-140 {
  min-width: 140px !important;
}

.min-width-120 {
  min-width: 120px !important;
}

.w-125px {
  width: 125px;
}

.min-width-115 {
  min-width: 115px !important;
}

.min-width-110 {
  min-width: 110px !important;
}

.min-width-109 {
  min-width: 109px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.min-width-95 {
  min-width: 95px !important;
}

.min-width-80 {
  min-width: 80px !important;
}

.min-width-70 {
  min-width: 70px !important;
}

.min-width-60 {
  min-width: 60px !important;
}

.min-width-40 {
  min-width: 40px !important;
}

.min-width-45 {
  min-width: 45px !important;
}

.min-width-38 {
  min-width: 38px !important;
}

.min-width-30 {
  min-width: 30px !important;
}

.min-width-54 {
  min-width: 54px !important;
}

.max-width-120 {
  max-width: 120px !important;
}

.width-80 {
  width: 80px;
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-auto {
  height: auto !important;
}

/* ========== position =========== */
.position-relative {
  position: relative;
}

.position-static {
  position: static;
}

/* ========== wrap & overflow =========== */
.radio-nowrap {
  white-space: nowrap;
}

.space-nowrap {
  white-space: nowrap;
}

.overflow-auto {
  overflow: auto;
}

/* ========== font-size =========== */
.fs-0 {
  font-size: 0 !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.br-spac-ini {
  border-spacing: initial;
}

/* ========== typography =========== */
.grey-text {
  color: #808080;
  font-weight: 600;
  font-size: 12px;
  margin-top: 6px;
}

.green-text {
  color: var(--primaryblue);
  opacity: 0.95;
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-danger {
  color: var(--danger);
}

/* ========== components =========== */

.ant-layout {
  background-color: var(--backgroundColor) !important;
}

.main-layout .ant-layout-content {
  background-color: transparent !important;
}


.ant-card.custom-card {
  background-color: var(--cardbkgcolor) !important;
  border-radius: 15px !important;
}

.custom-card .ant-card-head-title {
  font-weight: 600;
  padding-top: 24px;
  color: rgba(0, 0, 0, .85);
  padding-bottom: 0px;
}

.ant-card.custom-card.custom-grey-card {
  background-color: #D7D7D6 !important;
}

/* ========== table =========== */
.table-outer {
  margin-right: calc(-1 * var(--bodymargin));
}


.custom-table table {
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
  margin-top: -16px;
  width: 100%;
  padding-right: calc(var(--bodymargin) - 0px);
}

.custom-table .ant-table {
  background-color: transparent;
  max-height: 350px;
}

.custom-table .ant-table:not(.no-data-table.custom-table .ant-table) {
  min-height: 100px;
}

.custom-table .ant-table-thead,
.custom-table.multi-head .ant-table-thead {
  background-color: var(--tableheader);
}

.custom-table .ant-table-thead>tr>th {
  color: var(--primaryblue);
  padding: 6px 8px;
  font-weight: 600;
  background-color: var(--tableheader);
  font-size: 11px;
  text-align: left;
  letter-spacing: -0.2px;
  position: sticky;
  top: 0px;
  z-index: 1;
  min-width: 54px;
  white-space: nowrap;
}

.custom-table .ant-table-tbody>tr>td {
  font-size: var(--tblfs);
  height: 38px;
  padding: 4px 8px;
  text-align: left;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--tb-border);
  background-color: white;
  border-spacing: initial;
  /* spacing issue in table on edit row */
  line-height: 1.3;
}

.custom-table .ant-table-tbody>tr>td .ant-input,
.custom-table .ant-table-tbody>tr>td .ant-input-number,
.custom-table .ant-table-tbody>tr>td .ant-picker,
.custom-table .ant-table-tbody>tr>td .ant-input-search .ant-input-affix-wrapper,
.custom-table .ant-table-tbody>tr>td .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: var(--tblfs) !important;
}

.custom-table.td-top td {
  vertical-align: top;
}

.custom-table td:first-child {
  border-left: 1px solid;
  border-color: inherit;
  border-radius: var(--tb-border-radius) 0 0 var(--tb-border-radius);
  height: inherit;
}

.custom-table td:last-child {
  border-right: 1px solid;
  border-color: inherit;
  border-radius: 0 var(--tb-border-radius) var(--tb-border-radius) 0;
  height: inherit;
}

.custom-table tr td:only-child {
  border-radius: var(--tb-border-radius);
  text-align: center;
}

.custom-table tr:hover {
  outline: 1px solid var(--tb-border);
  border-radius: var(--tb-border-radius);
}

.custom-table .ant-table-filter-trigger.active {
  color: var(--primaryblue);
}

.custom-table.ant-table-wrapper:not(.scrollsync-outer .custom-table.ant-table-wrapper) {
  overflow-x: auto;
}

.custom-table .ant-table-thead>tr>th.status-column {
  min-width: 30px;
}

.action-column {
  text-align: center;
  white-space: nowrap;
}

.action-column * {
  height: 13px;
  cursor: pointer;
}

.action-column .ant-btn-icon-only {
  height: auto;
  width: auto;
}

.action-column .ant-btn-icon-only:hover,
.action-column .ant-btn-icon-only:focus {
  background-color: transparent;
}

.action-column>*:not(:last-child) {
  margin-right: 10px;
}

.table-badge .ant-badge-status-dot,
.status-column .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.table-badge .ant-badge-status-text,
.status-column .ant-badge-status-text {
  margin-left: 0;
}

.table-badge .ant-badge-status-success,
.status-column .ant-badge-status-success {
  background-color: var(--success);
}

.table-badge .ant-badge-status-error,
.status-column .ant-badge-status-error {
  background-color: var(--danger);
}

.p-icon.ant-btn,
.p-icon.ant-btn:hover,
.p-icon.ant-btn:focus,
.p-icon.ant-btn:active {
  border: 1px solid var(--primaryblue);
  color: inherit;
  transition: 0s;
  background-color: transparent;
  border-radius: 5px;
  padding: 0 3px;
  font-weight: bold;
  height: auto;
}

.p-icon.ant-btn span {
  font-size: 9px;
}

.p-icon.cc-icon {
  background-color: var(--primaryblue);
  color: white;
}

.selected td .p-icon.cc-icon.ant-btn {
  background-color: white;
}

.selected td .p-icon.cc-icon:not(.p-icon.cc-icon.red-cc, .p-icon.cc-icon.green-cc, .p-icon.cc-icon.amber-cc) * {
  color: var(--primaryblue);
}

/* selected table row */
.selected td {
  background-color: var(--primaryblue) !important;
}

.selected td img {
  filter: invert(110%) sepia(15%) saturate(77%) hue-rotate(81deg) brightness(105%) contrast(91%);
}

.selected td * {
  color: white;
}

.selected td .link-color {
  color: var(--redText);
  text-decoration: underline;
}

.selected td [class*="-disabled"] {
  color: var(--disabled);
}

.selected td .p-icon.ant-btn {
  color: white;
  border-color: white;
}

/* xx */

/* multihead table */
.custom-table.multi-head .ant-table-thead>tr:nth-child(2)>th {
  position: sticky;
  top: 38px;
}

.custom-table.multi-head .ant-table-thead>tr>th:after {
  content: '';
  position: absolute;
  background-color: var(--tableheader);
  width: 100%;
  height: 18px;
  top: -16px;
  left: 0;
}


.custom-table.multi-head .ant-table-thead>tr:first-child th {
  padding-bottom: 0;
}

/* xx */

/* ========== input =========== */
.ant-form-item-label>label,
label {
  font-size: 11px !important;
  color: var(--label) !important;
  font-weight: 600;
}

label.custom-label {
  line-height: 1.5715;
  display: inline-block;
  width: 100%;
  padding-bottom: 2px;
}

.label-border {
  margin-bottom: 8px;
  border-bottom: 1px solid var(--tb-border);
}

.label-w100 label {
  width: 100%;
}

.ant-form-item-label,
label.custom-label {
  padding-bottom: 2px !important;
  padding-left: 4px;
}

.ant-form-item-control-input {
  width: auto;
  min-height: auto !important;
}

.form-item-mb-1 .ant-form-item {
  margin-bottom: 1px;
}

.label-10px label {
  font-size: 10px !important;
}

.formitem-mb {
  margin: 0 0 24px;
}


.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-multiple:not(.ant-select-customize-input).ant-select-allow-clear .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:not(.ant-select-borderless .ant-select-selector) {
  border-color: var(--tb-border);
  border-width: 1px;
  box-shadow: none !important;
}

.ant-input:not(.ant-table-filter-dropdown-search .ant-input, .warehouse-screen .ant-input, .colheader-search .ant-input),
.ant-input-number,
.ant-picker,
.ant-input-search .ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input, .sm-select) .ant-select-selector {
  border-color: var(--tb-border);
  border-radius: 5px !important;
  min-height: var(--inputheight) !important;
  line-height: normal;
  width: 100%;
  font-size: var(--ipfs) !important;
}

.sm-select .ant-select-selector {
  border-color: var(--tb-border);
  border-radius: 5px !important;
}

.local .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: var(--inputheight) !important;
  border-color: var(--tb-border) !important;
}

.local .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 26px !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-focused .ant-picker:focus,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:not(.ant-select-disabled, .ant-select-customize-input, .border-primary):hover .ant-select-selector:not(.ant-select-borderless .ant-select-selector),
.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
.ant-select:not(.ant-select-customize-input) .ant-select-selector-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--tb-border) !important;
  border-width: 2px;
  box-shadow: none !important;
  transition: 0s;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input, .sm-select) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input, .sm-select) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input, .sm-select) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2 !important;
}

.ant-picker {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-picker-input>input {
  font-size: var(--ipfs) !important;
}

.ant-input::placeholder,
.ant-picker-input>input::placeholder {
  font-size: 0.85rem;
}

.field-horizontal>.ant-row {
  flex-direction: row !important;
}

.ant-input-number {
  width: 100% !important;
}

.ant-input-number-sm {
  display: flex;
  align-items: center;
}

.ant-input-number-sm input {
  height: calc(var(--inputheight) - 4px) !important;
}

.br-input.ant-input,
.br-input.ant-input-number,
.br-input.ant-picker,
.br-input.ant-picker-input,
.br-input.ant-input-search .ant-input-affix-wrapper,
.br-input.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 20px !important;
}

/* ========== custom search =========== */
.search-input.ant-input-search .ant-input-affix-wrapper input.ant-input,
.search-field.ant-input-search .ant-input-affix-wrapper input.ant-input {
  height: calc(var(--inputheight) - 4px) !important;
}

.search-input.ant-input-search .ant-input-affix-wrapper {
  border-radius: 20px !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.search-input.ant-input-group-wrapper {
  width: 250px;
}

.search-input span.ant-input-group-addon,
.search-input .ant-input-search-button,
.search-field span.ant-input-group-addon,
.search-field .ant-input-search-button {
  border-radius: 50% !important;
  height: calc(var(--inputheight) - 4px) !important;
  width: calc(var(--inputheight) - 2px) !important;
  background-color: white;
  border: none !important;
  box-shadow: none !important;
  z-index: 1;
}

.search-input .ant-input-suffix,
.search-field .ant-input-suffix {
  margin-right: calc(var(--inputheight) - 6px) !important;
}

.search-input .ant-input-group-addon,
.search-field .ant-input-group-addon {
  background-color: transparent !important;
  box-shadow: none;
}

.search-input .ant-input-affix-wrapper,
.search-field .ant-input-affix-wrapper {
  width: calc(100% + var(--inputheight)) !important;
  border: none;
  outline: 1px solid var(--tb-border);
}

.search-input .ant-input-affix-wrapper:hover,
.search-input .ant-input-affix-wrapper:focus,
.search-input .ant-input-affix-wrapper-focused,
.search-field .ant-input-affix-wrapper:hover,
.search-field .ant-input-affix-wrapper:focus,
.search-field .ant-input-affix-wrapper-focused {
  border-color: transparent;
  outline: 2px solid var(--tb-border);
}

textarea.custom-textarea.ant-input{
  border: transparent;
  outline: 1px solid var(--tb-border);
}

textarea.custom-textarea.ant-input:hover,
textarea.custom-textarea.ant-input:focus
{
  border: transparent;
  outline: 2px solid var(--tb-border);
}

/* ========== disabled input =========== */
.ant-input[disabled],
.ant-input-number[disabled],
.ant-input-number-disabled,
.ant-picker[disabled],
.ant-picker.ant-picker-disabled,
.ant-picker-input[disabled],
.ant-input-search .ant-input-affix-wrapper[disabled],
.ant-input-search .ant-input-affix-wrapper-disabled,
.ant-select[disabled]:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector:not(.ant-select-borderless .ant-select-selector) {
  background-color: var(--disabled) !important;
}


/* ========== button =========== */
.custom-button:not(:disabled), .custom-button:disabled {
  border-radius: 4px;
  font-size: 1rem;
  min-width: 74px;
  height: var(--inputheight);
}

.custom-button:not(:disabled) {
  background-color: var(--primaryblue) !important;
  border: none;
  color: white !important;
}

.custom-button:not(:disabled):hover,
.custom-button:not(:disabled):focus {
  background-color: var(--primaryblue) !important;
  color: white !important;
  border-radius: 4px;
  box-shadow: none;
}

.custom-button:not(:last-child) {
  margin-right: 8px;
}

.custom-sm-icon-button.ant-btn-icon-only {
  height: calc(var(--inputheight) - 2px);
  width: calc(var(--inputheight) - 2px);
}

.custom-icon-button:not(.custom-icon-button.custom-sm-icon-button).ant-btn-icon-only {
  height: calc(var(--inputheight) + 0px);
  width: calc(var(--inputheight) + 0px);
}

.custom-icon-button.ant-btn-icon-only {
  background-color: var(--primaryblue);
  border: none;
  color: white;
  border-radius: 4px;
}

.custom-icon-button:not(:disabled),
.custom-icon-button:not(:disabled):hover,
.custom-icon-button:not(:disabled):focus {
  background-color: var(--primaryblue);
  color: white;
}

.custom-icon-button[disabled],
.custom-icon-button[disabled]:hover,
.custom-icon-button[disabled]:focus {
  border: 1px solid #d9d9d9;
}

.custom-icon-border {
  border: 1px solid white !important;
}

.image-btn.ant-btn, .image-btn.ant-btn[disabled], .image-btn.ant-btn[disabled]:active, .image-btn.ant-btn[disabled]:focus, .image-btn.ant-btn[disabled]:hover {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  height: var(--inputheight);
}

.image-btn img {
  height: 100%;
}

.image-btn:disabled img {
  opacity: 0.3;
}

.image-btn.clear-btn:not(:disabled) {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c3c1c1;
}

.close-icon {
  color: white;
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
}

.image-btn.clear-btn:not(:disabled):hover,
.image-btn.clear-btn:not(:disabled):focus,
.image-btn.clear-btn:not(:disabled):active {
  background-color: var(--redText);
  transition: 0s;
}

.green-fliter img {
  filter: invert(42%) sepia(57%) saturate(1362%) hue-rotate(51deg) brightness(102%) contrast(98%);
}

/* ========== disabled button =========== */

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  border: 1px solid #d9d9d9;
  background-color: var(--disabled) !important;
}

/* ========== modal =========== */
.custom-modal.ant-modal {
  border-radius: var(--modalborderradius);
}

.custom-modal .ant-modal-body {
  background-color: var(--cardbkgcolor);
  border-radius: 0 0 var(--modalborderradius) var(--modalborderradius) !important;
  max-height: calc(100vh - 40px - 60px);
  overflow: auto;
}

.custom-modal .ant-modal-header {
  padding: 18px 24px;
  background-color: var(--cardbkgcolor);
  border-radius: var(--modalborderradius) var(--modalborderradius) 0 0 !important;
  border-bottom: 1px solid #e5e5e5;

}

.custom-modal .ant-modal-title,
.custom-modal .ant-modal-close-x {
  color: rgba(0, 0, 0, 0.95);
}

.custom-modal .ant-modal-title {
  font-size: 18px;
}

.curved-modal .ant-modal-body {
  border-radius: 0 0 var(--modalborderradius) var(--modalborderradius) !important;
}

.curved-modal .ant-modal-header {
  border-radius: var(--modalborderradius) var(--modalborderradius) 0 0 !important;
}

/* ========== pop confirm button  =========== */
.ant-popover-buttons .ant-btn-primary,
.ant-popover-buttons .ant-btn-primary:hover,
.ant-popover-buttons .ant-btn-primary:focus {
  background-color: var(--primaryblue);
  border: none;
  color: white;
}

.ant-popover-buttons .ant-btn,
.ant-popover-buttons .ant-btn:hover,
.ant-popover-buttons .ant-btn:focus {
  border: 1px solid #d9d9d9;
}

/* ========== badge & hr   =========== */
.custom-badge .ant-badge-count {
  background-color: var(--redText) !important;
}

.hr-color {
  opacity: 0.2;
}

/* ========== scroll =========== */

/* webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #d6d6d6;
  width: 8px;

}

::-webkit-scrollbar-thumb {
  background: #555555;
  border-radius: 5px
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .table-overflow.ant-col {
  z-index: 1;
}

.table-outer {
  box-shadow: -24px 0px 0px inset var(--cardbkgcolor);
}

.custom-table .ant-table {
  z-index: -1;
} */

/* ========== primary color =========== */
.ant-checkbox-checked:not(.ant-checkbox-disabled.ant-checkbox-checked) .ant-checkbox-inner {
  background-color: var(--primaryblue) !important;
  border-color: var(--primaryblue) !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled.ant-checkbox-checked) .ant-checkbox-inner,
.ant-radio-checked:not(.ant-radio-disabled.ant-radio-checked) .ant-radio-inner {
  border-color: var(--primaryblue) !important;
}

.ant-radio-inner:not(.ant-radio.ant-radio-disabled .ant-radio-inner)::after {
  background-color: var(--primaryblue) !important;
}

.ant-spin-dot-item,
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: var(--primaryblue) !important;
}

.ant-spin {
  color: var(--primaryblue) !important;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--primaryblue) !important;
}

.ant-layout-sider {
  background: var(--primaryblue) !important;
}

/* disabled */
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--disabled) !important;
  border-color: var(--tb-border) !important;
}

/* inbound */
/* comment */
.ant-comment-content-detail {
  background-color: var(--cardbkgcolor);
  padding: 12px 18px;
  border-radius: var(--modalborderradius);
  font-size: 1rem;
}

/* error */
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px;
}

.prev-img {
  width: -webkit-fill-available;
  height: auto;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: "*";
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.nth-td-p-0 td:nth-child(5),
.nth-td-p-0 td:nth-child(6) {
  padding: 0 !important;
}

.noti-modal {
  top: 25px !important;
}

.noti-modal .ant-modal-body {
  overflow: auto;
  height: 80vh;
}

.pill-card-header .ant-card-head {
  min-height: 0;
  border: none;
}

.pill-card-header .ant-card-head-title {
  position: absolute;
  font-size: x-small;
  background: var(--primaryblue);
  color: #fff;
  padding: 0.25rem 1rem;
  border-radius: 15px;
}

.pill-card-header.ant-card {
  border-color: var(--primaryblue);
}

.hidden-print-table {
  display: none !important;
}

@media screen and (max-width: 767px) {
  :root {
    --bodymargin: 12px;
  }

  .custom-card .ant-card-body {
    padding: calc(2 * var(--bodymargin)) var(--bodymargin) !important;
  }

  .table-outer {
    margin-right: calc(-1 * var(--bodymargin));
  }

  .custom-table table {
    padding-right: var(--bodymargin);
  }

  /* .table-outer {
  box-shadow: calc(-1 * var(--bodymargin)) 0 0 0 var(--cardbkgcolor) inset;
} */
  .search-input.ant-input-group-wrapper {
    width: 180px;
  }

  .table-overflow.ant-col {
    /* width: 100%; */
    /* padding-right: 0 !important; */
  }

  .custom-table table {
    padding-right: 12px;
  }

}

.countdowns {
  display: contents;
}

.red.countdowns {
  color: red;
}

.green.countdowns {
  color: green;
}

.badge-circle.badge-circle-sm {
  min-width: 8px;
  height: 8px;
}

.deliver .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid transparent !important;
  background-color: transparent;
}

.deliver.red-border .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--redText) !important;
}

.deliver .ant-select-selection-placeholder {
  font-weight: 400;
}

@media (min-width: 1200px) {
  .xl-auto {
    flex: auto !important;
    max-width: 100% !important;
  }

  .xl-pl-4 {
    padding-left: 1.5rem !important;
  }
}

@media (max-width: 1199px) {
  .xl-pl-4 {
    padding-left: 0 !important;
  }
}
@media (max-width: 991px) {
  .md-left {
      text-align: left;
  }
}
/* firefox scroll */
/* @-moz-document url-prefix() {

  .custom-table.ant-table-wrapper:not(.scrollsync-outer .custom-table.ant-table-wrapper) {
    overflow-x: auto;
    scrollbar-color: #555555 #d6d6d6;
    scrollbar-width: thin !important;
    scrollbar-gutter: stable both-edges;
  }
} */