.tabs {
  width: calc(100% - 4px) !important;
  background-color: var(--secondarygrey);
  color: var(--primaryblue);
  border-radius: 10px 10px 0 0;
  padding: 0 8px;
  min-height: 38px;
  line-height: 1.1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}

.active-tab {
  background-color: var(--primaryblue);
  color: white;
  border-bottom: 3px solid var(--redText);
}

.tabs:hover,
.tabs:focus,
.tabs:active {
  background-color: var(--primaryblue);
  color: white;
  border-bottom: 3px solid var(--redText);
}

.custom-slider.slick-slider {
  width: 100%;
  /* without arrows */
  margin: auto;
}

.hidden-table-icon {
  visibility: hidden;
  width: 13px;
}

.red-border {
  color: red;
  font-weight: 900;
}

.ant-select-selection-placeholder {
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .sys-para-slider.slick-slider {
    width: calc(100% - 50px);
    /* with arrows */
  }
}

@media screen and (max-width: 767px) {
  .custom-slider.slick-slider {
    width: calc(100% - 4*var(--bodymargin));
    margin: auto;
  }
}
.custom-card-1{
  height: calc(100vh - 34%);
}
.questions-table{
  height: calc(100vh - 34%);
}

.email-icon{
  font-size: 12px;
}

.added-emails{
  margin: 2px;
}

.save-section{
  margin: 10px 2px;
}

.save-text{
  font-style: oblique;
  font-weight: 500;
}

.custom-table-header{
  font-weight: 600;
    padding-top: 24px;
    color: rgba(0, 0, 0, .85);
    padding-bottom: 0px;
}

.amber-indicator{
  margin-bottom: 10px;
  margin-right: 3px;
}

.modal-fields{
  margin-bottom: 10px;
}

.bottom-card {
  max-height: 100px;
}

.radio-text {
  font-weight: 500;
  font-size: 14px !important;
}

.label-1{
  margin-bottom: 15px;
}

.h3{
  white-space: pre;
}

.add-ques{
  display: flex;
}

.question-card{
  display: flex;
  margin-top: 10px;
  flex-direction: row-reverse;
}

.qust-table .ant-table {
  /* max-height: calc(100vh - 612px); */
  max-height: calc(100vh - 350px);
}

.tags{
  color: var(--primaryblue);
  background: var(--tableheader);
  border-radius: 5px;
  margin-left: 5px;
}
.amber-input{
  margin: 5px;
  margin-left: auto;
}

.add-mcq{
  position: absolute;
  z-index: 12;
}
