.notes-card {
  min-height: calc(100vh - var(--headerheight) - 73px) !important;
}

.comment-section {
  padding: 24px !important;
  border-radius: 15px;
  border: 1px solid #0a12270f;
  max-height: 460px;
  overflow: auto;
}

.comment-section .ant-comment-inner {
  padding: 0 !important;
  margin-bottom: 28px
}

.comment-section .ant-comment-content-detail {
  background-color: #e4e4e4;
  padding: 18px 24px;
  font-size: 1rem;
  border-radius: 9px;
  position: relative; /* for view attachment icon  */
}

.editor-comment.ant-comment {
  position: sticky !important;
  bottom: -24px;
  background-color: #f2f2f2 !important;
  padding-bottom: 23px;
}

.editor-comment .ant-comment-content-detail {
  background-color: transparent;
  padding: 0;
}

.editor-comment .ant-comment-inner {
  margin-bottom: 0;
}

.comment-section .ant-comment-content-author-name {
  width: 100%;
}

.comment-section .ant-comment-content-author-name>* {
  color: rgb(0 0 0 / 75%) !important;
}

.comment-section .ant-comment-actions>li>span,
.ant-comment-actions img {
  font-size: 18px !important;
  width: 18px;
}

.add-notes {
  width: calc(100% - 32px - 8px) !important;
}

.add-notes .ant-input-group .ant-input {
  width: calc(100% + var(--inputheight));
}

.add-notes .ant-input-affix-wrapper {
  width: calc(100% + var(--inputheight) + 2px) !important;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-flex;
  align-items: center;
  border-radius: var(--tb-border-radius);
}

.add-notes .ant-input-group-addon {
  padding: 0;
  border: none;
  background-color: transparent;
}

.add-notes .ant-input-group-addon .ant-btn,
.add-notes .ant-input-group-addon.ant-btn:hover,
.add-notes .ant-input-group-addon .ant-btn:focus {
  height: calc(var(--inputheight) + 2px);
  width: calc(var(--inputheight) + 2px);
  padding: 0;
  z-index: 1;
  border: none;
  border-radius: 5px;
}

.add-notes .ant-input-group-addon .ant-btn:not(:disabled),
.add-notes .ant-input-group-addon.ant-btn:not(:disabled):hover,
.add-notes .ant-input-group-addon .ant-btn:not(:disabled):focus {
  background-color: var(--primaryblue);
}

.add-notes .ant-input-group-addon .ant-btn[disabled] {
  border: 1px solid #d9d9d9;
  background-color: var(--disabled);
}

.add-notes .ant-input-group-addon .ant-btn[disabled] img,
.add-notes .ant-input-group-addon.ant-btn[disabled]:hover img,
.add-notes .ant-input-group-addon .ant-btn[disabled]:focus img {
  filter: contrast(0.5);
  opacity: 0.7;
}

.comment-section .ant-comment-actions img {
  opacity: 0.6;
}

.like-po-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.like-po-wrapper:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.likes {
  font-size: 12px;
}

.like-popover {
  width: 200px;
  max-height: 313px;
  overflow: auto;
}

.like-popover .ant-popover-inner {
  border-radius: 8px;

}

.like-popover .ant-popover-inner-content {
  max-height: 313px !important;
  overflow: auto !important;
}

/* add outer class */

.like-name {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
}

.like-position {
  margin-bottom: 0;
  font-size: 10px;
  color: dimgray;
}

.like-po-wrapper svg {
  font-size: 16px;
}

.add-notes .ant-input-prefix {
  height: max-content;
}

.reply-to {
  background-color: #ececec;
  font-size: 10px;
  padding: 1px 8px;
  border-radius: 11px;
  font-weight: 600;
  color: #606060;
}

.view-attachment {
  position: absolute;
  right: 0;
  height: 100%;
  background-color: #f5f5f5;
  top: 0;
  min-width: 50px;
  max-width: 120px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #dadada;
  font-size: x-large;
  text-align: center;
  color: var(--primaryblue);
}

.view-attachment:hover,
.view-attachment:focus {
  color: var(--primaryblue);
}

.attachment-info {
  font-size: 8px;
  margin-top: 4px;
  line-height: normal;
  /* below css is for max 15 char attachment name  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

.view-icon-outer {
  padding-right: 80px;
}

.liked {
  color: #0a1227 !important;
}

.custom-upload-button {
  margin-left: 5px;
  vertical-align: text-top;
}

/* attachment */
.editor-comment .ant-upload-list-text .ant-upload-list-item-name {
  flex: none !important;
  width: auto;
}