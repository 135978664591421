.warehouse-table {
  --primaryblue: #0a1227;
  --secondaryblue: #7d9bc1;
  --backgroundColor: #d7d7d7;
  --cardbkgcolor: #f2f2f2;

  /* table var */
  --tableheader: #d7d7d7;
  --tb-border-radius: 5px;
  --tb-border: #d7d7d7;
  --red: #D02139;
  --orange: #eaaa00;
  --green: #008000;

  /* input */
  --label: #333333;
  --inputheight: 28px;

  overflow: auto;
}
.warehouse-screen:not(.ant-drawer-wrapper-body .warehouse-screen) {
  margin: 2vw;
  min-height: calc(100vh - 4vw);
  width: calc(100% - 4vw);
}

.warehouse-screen.warehouse-dashboard {
  min-height: auto;
}

.warehouse-screen [class*="col-"] {
  padding: 0;
}

.row-1[class*="col-"] {
  padding-right: 2vw;
  margin-bottom: 1.2vw;
}

.warehouse-screen .ant-input-number {
  width: 20% !IMPORTANT;
  flex-shrink: 0;
  margin: 0 10px;
  display: flex;
}

.warehouse-screen .ant-input-number-input-wrap {
  display: flex;
  align-items: center;
}

/* not really needed */
.warehouse-screen .ant-checkbox+span {
  display: flex;
  font-size: clamp(14px, 1.5vw, 1.5vw);
  align-items: center;
}

.warehouse-screen .ant-checkbox {
  transform: scale(2.2);
  margin-right: 20px;
}

.custom-button:not(:disabled).warehouse-button:not(:disabled) {
  border-radius: 1vw;
  font-size: clamp(14px, 1.6vw, 1.6vw) !important;
  height: auto;
  padding: 0.7vw 2vw;
  display: block;
}

.warehouse-search {
  background-color: white;
  border-radius: 2vw;
  transition: 0s;
}

.warehouse-search .ant-input {
  border: none;
  background-color: transparent !important;
}

.warehouse-search .ant-input-group-wrapper {
  transition: 2s;
}

.warehouse-search.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  min-width: clamp(30px, 4vw, 4vw);
  height: clamp(30px, 4vw, 4vw) !important;
  border-radius: 50%;
  background-color: var(--primaryblue);
  border: none;
}

.warehouse-search .anticon {
  font-size: clamp(16px, 2vw, 2vw);
}

.warehouse-search .anticon:not(.ant-input-clear-icon .anticon-close-circle) {
  color: white;
}

.warehouse-screen .warehouse-search.ant-input-search .ant-input-affix-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
}

.warehouse-screen .ant-input-search.search-w0 .ant-input-affix-wrapper {
  min-width: 0;
  width: 0 !important;
  padding: 0px !important;
}

.warehouse-screen .ant-input-search.search-w100 .ant-input-affix-wrapper {
  min-width: 16vw !important;
  width: 16vw;
  border-radius: 2vw !important;
}

.warehouse-screen .ant-input::placeholder,
.warehouse-screen .ant-picker-input>input::placeholder {
  font-size: clamp(14px, 1.4vw, 1.4vw)
}

.warehouse-screen .dr-search .ant-input-search-button {
  position: absolute;
  right: 0.5vw;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  box-shadow: none;
}

.warehouse-screen .dr-search .anticon-search {
  font-size: 1.5vw;
  opacity: 0.8;
}

.warehouse-table td:hover,
.warehouse-screen tr:hover td,
.warehouse-screen .ant-table-tbody>tr.ant-table-row:hover>td,
.warehouse-screen .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background-color: var(--primaryblue) !important;
  color: white;
}

.warehouse-table .ant-table table {
  width: 100%;
  background-color: var(--primaryblue);
}

.warehouse-table thead.ant-table-thead,
.warehouse-table .ant-table-tbody>tr>td,
.nested-table thead.ant-table-thead,
.nested-table .ant-table-tbody>tr>td,
.warehouse-screen .ant-table-thead>tr>th {
  background-color: var(--primaryblue);
}

.warehouse-table:not(.warehouse-table.nested-table) .ant-table-cell:not(.recovery-dashboard .ant-table-cell, .haulout-nested-table, .ant-table-cell) {
  width: 20%;
}

.bkd-table:not(.warehouse-table.nested-table.bkd-nested-table) .ant-table-cell:not(.warehouse-table.nested-table.bkd-nested-table .ant-table-cell) {
  width: 20%;
}

.nested-table .ant-statistic-content-value {
  text-align: right;
  width: 13.9vw;
  display: block;
  margin: auto;
}

.nested-table:not(.nested-table.bkd-nested-table) td {
  text-align: right !important;
}

.warehouse-table .ant-table-thead>tr {
  border-bottom: 0.1vw solid var(--tb-border);

}

.warehouse-table .ant-table-thead>tr>th {
  background-color: var(--primaryblue);
  padding: 1vw;
  font-weight: 600;
  text-align: left;
  letter-spacing: -0.2px;
  position: sticky;
  top: 0px;
  z-index: 1;
  min-width: 54px;
  text-align: center;
  color: var(--tb-border);
  text-transform: uppercase;
  border-bottom: 0.2vw solid var(--red);
}

.warehouse-table .ant-table-tbody>tr>td {
  padding: 0.8vw 1.4vw;
  background-color: var(--primaryblue);
  text-align: center;
  color: var(--tb-border);
  font-weight: 900;
  border-bottom: 0.2vw solid #464646;
}


.warehouse-table tr td:only-child {
  text-align: center;
}

.nested-table.warehouse-table>tbody>tr:last-child>td {
  border-bottom: none;
}

.nested-table {
  width: 100%;
  border-spacing: 0;
  border-bottom: 0.1vw solid var(--tb-border);
  padding: 1vw;
  padding-top: 0;
  padding-bottom: 0;
}

.nested-table .ant-table-thead>tr>th {
  display: none;
}

.nested-table.warehouse-table>tbody>tr>td {
  text-align: right;
}

.warehouse-table .green {
  color: var(--green);
}

.warehouse-table .red {
  color: var(--red);
}

.warehouse-table .amber {
  color: var(--orange);
}

.warehouse-table .ant-statistic, .warehouse-table .ant-statistic-content {
  font-size: inherit;
}

.warehouse-table .green .ant-statistic-content-value {
  color: var(--green);
  font-size: inherit;
}

.warehouse-table .red .ant-statistic-content-value {
  color: var(--red);
  font-size: inherit;
}

.warehouse-table .amber .ant-statistic-content-value {
  color: var(--orange);
  font-size: inherit;
}

.warehouse-screen .ant-table-tbody>tr>td>.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table,
.warehouse-screen .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}

.warehouse-table .ant-table-tbody>tr.ant-table-expanded-row>td {
  border-bottom: none;
  padding: 0;
}


.warehouse-screen .ant-input,
.warehouse-screen .ant-input-number,
.warehouse-screen .ant-input-number-sm input,
.warehouse-screen .ant-picker,
.warehouse-screen .ant-input-search .ant-input-affix-wrapper,
.warehouse-screen .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.warehouse-screen .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
.warehouse-screen .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  font-size: clamp(14px, 1.6vw, 1.6vw) !important;
  height: 4vw !important;
}

.warehouse-screen .ant-select.ant-select-sm.ant-select-multiple.ant-select-show-search .ant-select-selector {
  overflow: auto;
}

.warehouse-screen .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.warehouse-screen .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.warehouse-screen .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.warehouse-screen .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector:after,
.warehouse-screen .ant-select-multiple.ant-select-sm .ant-select-selection-search,
.warehouse-screen .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.warehouse-screen .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  display: flex;
  align-items: center;
  line-height: 4vw;
}

.warehouse-screen .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.warehouse-screen .ant-input,
.warehouse-screen .ant-input-number,
.warehouse-screen .ant-picker,
.warehouse-screen .ant-input-search .ant-input-affix-wrapper,
.warehouse-screen .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 1vw !important;
  background-color: white;
  padding: 0.5vw 1vw !important;
}

.warehouse-screen .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector,
.warehouse-screen .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.warehouse-screen .ant-input-number {
  padding: 0.5vw 1vw !important;
  border: none;
  background-color: white;
}

.warehouse-screen .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  padding: 0 1.4vw !important;
  display: flex;
  align-items: center;

}

.warehouse-screen .ant-input-group-addon {
  background-color: transparent;
}

.warehouse-screen .ant-form-item-label {
  text-align: left;
}

.loc-search .ant-btn .anticon {
  font-size: 3vw;
}

.loc-search input {
  padding: 0.5vw 2vw !important;
}

.loc-search .ant-btn {
  border: none;
  background-color: transparent;
  z-index: 1;
}

.loc-search .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  left: -41px;
}

.warehouse-table tr:last-child .nested-table {
  margin-bottom: 0;
}

.warehouse-screen .ant-form-item {
  margin-bottom: 1.2vw;
}

.mr-2vw {
  margin-right: 2vw;
}

.ml-2vw {
  margin-left: 2vw;
}

.mt-2vw {
  margin-top: 2vw;
}

.mt-1vw {
  margin-top: 1vw;
}

.mr-1vw {
  margin-right: 1vw;
}

.mb-1vw { 
  margin-bottom: 1vw;
}

.mb-2vw {
  margin-bottom: 2vw;
}

.mb-1p5 {
  margin-bottom: 1.5vw;
}

.ml-neg8 {
  margin-left: 0.8vw;
}

.pl-2vw {
  padding-left: 2vw;
}

.warehouse-screen .ant-col-17 {
  max-width: calc(70.83333333% - 2vw) !important;
}


.warehouse-select .ant-select-item-option-content {
  font-size: 1.7vw;
  min-height: 3vw;
  display: flex;
  align-items: center;
}

.warehouse-screen .ant-table-cell .ant-table-row-expand-icon-cell,
.warehouse-screen .ant-table-row-expand-icon-cell:not(.recovery-table .ant-table-row-expand-icon-cell, .haulout-dash .ant-table-row-expand-icon-cell) {
  display: none;
}

.warehouse-screen .ant-select-arrow,
.warehouse-screen .ant-select-clear {
  font-size: clamp(14px, 1.2vw, 1.2vw)
}

.warehouse-screen .ant-select-clear {
  top: 42%;
  right: 3vw;
}

.warehouse-screen .ant-form-item-label>label,
.warehouse-screen .ant-form-item-label>label.transparent,
.warehouse-screen label.transparent,
.warehouse-screen label {
  font-size: clamp(14px, 1.4vw, 1.4vw) !important;
}

.warehouse-screen .ant-form-item-label>label.transparent,
.warehouse-screen label.transparent {
  line-height: 35px;
}

/* === Dest column alignment === */

.nested-table.warehouse-table .ant-table-tbody>tr>td:last-child {
  text-align: center;
}

.nested-table td:last-child {
  text-align: center !important;
}

.warehouse-screen .dis-server {
  font-size: 1vw;
}

.hidden-countdown {
  display: none;
}

.spin-font .ant-spin-dot {
  font-size: 2vw !important;
}

.spin-font {
  margin-bottom: 2vw !important;
}

.warehouse-drawer .ant-drawer-content-wrapper {
  min-height: 29vw;
}

.warehouse-drawer .ant-drawer-body {
  background-color: #efefef;
}

.warehouse-screen .ant-input-number.uld-time .ant-input-number-input {
  height: 4vw;
}

.warehouse-screen .ant-form-item-label {
  width: 100%;
  line-height: 2vw;
}

.selected-filter {
  font-size: clamp( 14px, 2vw, 2vw);
  width: 100%;
}

.ant-btn-link.close-btn,
.ant-btn-link.close-btn:hover,
.ant-btn-link.close-btn:focus {
  color: #3d3d3d;
  font-size: 1.6vw;
  min-width: 3.5vw;
  height: 3.5vw;
  border-radius: 50%;
}

.ant-btn-link.close-btn:hover {
  background-color: #d8d4d4;
}

.warehouse-screen .ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: auto;
  line-height: normal;
}

.blue .ant-select-item-option-content {
  color: blue;
}

.w-action-btn .ant-space-item:not(:last-child) {
  margin-right: 1vw;
}

.warehouse-filter.ant-btn-icon-only.ant-btn-lg {
  min-width: clamp(30px, 4vw, 4vw);
  height: clamp(30px, 4vw, 4vw) !important;
  border-radius: 50%;
}

.warehouse-filter.ant-btn-icon-only.ant-btn-lg>* {
  font-size: clamp(16px, 2vw, 2vw);
}

.btn-text {
  text-align: center;
  font-weight: 600;
  font-size: clamp(12px, 1vw, 1vw);
  margin-top: 0.3vw;
}
.clock-text {
  text-align: center;
  font-weight: 600;
  font-size: clamp(12px, 1vw, 1vw);
  margin-top: 0.3vw;
}
.clock-container{
  display: flex;
  justify-content: flex-end;
}
.rotate-180 {
  transform: rotate(180deg);
}

.warehouse-screen .ant-input-number.uld-time {
  width: 6vw !important;
}

/* RECOVERY DASHBOARD */

.warehouse-table.recovery-table .ant-table-thead>tr>th {
  white-space: nowrap;
}

.pr-1vw {
  padding-right: 1vw !important;
}

.pl-1vw {
  padding-left: 1vw !important;
}

.recovery-table .ant-table-row-expand-icon:focus,
.haulout-table .ant-table-row-expand-icon:focus,
.recovery-table .ant-table-row-expand-icon:hover,
.haulout-table .ant-table-row-expand-icon:hover {
  color: var(--red) !important;
}

.recovery-table .ant-table-row-expand-icon:after,
.haulout-table .ant-table-row-expand-icon:after {
  left: 43% !important;
  width: 0.3vw !important;
  transform: translateY(-50%) rotate(rotate(90deg));
}

.recovery-table .ant-table-row-expand-icon:before,
.haulout-table .ant-table-row-expand-icon:before {
  top: 43% !important;
  height: 0.3vw !important;
  transform: translateY(-50%) rotate(rotate(90deg));
}

.recovery-table .ant-table-row-expand-icon,
.haulout-table .ant-table-row-expand-icon {
  width: 2vw !important;
  height: 2vw !important;
  background-color: transparent !important;
  border: none !important;
}

.warehouse-switch {
  transform: scale(1);
  margin-left: 1vw !important;
}

.warehouse-table.recovery-table .nested-table thead {
  display: none;
}

.warehouse-screen .recovery-table .ant-checkbox {
  margin-right: 0;
}

.warehouse-table.recovery-table .ant-table-thead>tr>th {
  padding: 1vw 0.4vw;
}

.dash-title {
  font-size: 3vw;
  text-align: center;
  padding: 1vw 1vw 0 0.8vw;
  margin-bottom: 2vw;
  text-transform: uppercase;
  border-bottom: 0.3vw solid #d0223a;
  width: fit-content;
  margin: 1vw auto;
  margin-bottom: 4vw;
  font-weight: 600;
}

.dash-loc {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.4vw;
}

.w-10vw {
  min-width: 10vw;
}

.w-16vw {
  min-width: 16vw;
}

.w-18vw {
  min-width: 18vw;
}

.w-20vw {
  min-width: 20vw;
}

.w-22vw {
  min-width: 22vw;
}

.warehouse-done.ant-btn,
.warehouse-done.ant-btn:hover,
.warehouse-done.ant-btn:focus {
  border-radius: 2vw;
  font-size: inherit;
  height: auto;
  padding: 0.1vw 0.9vw;
  display: block;
  font-weight: 500;
  background-color: #f4f4f4;
  border: none;
  color: black;
  min-width: 6vw;
}

.warehouse-done.warehouse-confirm.ant-btn {
  background-color: var(--redText);
  color: white;
}

.warehouse-done .ant-btn-loading-icon {
  font-size: 13px;
}

tr.ant-table-row:not(tr.ant-table-expanded-row, tr.ant-table-expanded-row tr.ant-table-row) {
  border-bottom: 0.1vw solid white;
}

/* On print */
@media print {
  .no-print {
    display: none !important;
  }

  .dash-title {
    font-size: 16px;
    text-align: center;
    border-bottom: 2px solid #d0223a;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px auto;
    padding: 0;
  }

  .warehouse-screen {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .warehouse-screen>p {
    margin-bottom: 0;
  }

  .mb-1p5 {
    margin-bottom: 0;
  }

  .selected-filter {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    font-size: 14px;
  }

  .sel-flt-rw .ant-col {
    flex: auto;
    margin-right: 10px;
    margin-bottom: 0 !important;
  }

  .warehouse-table:not(.warehouse-table.nested-table) .ant-table-cell:not(.recovery-dashboard .ant-table-cell) {
    width: auto;
  }

  .warehouse-table .ant-table table,
  .ant-layout {
    background-color: white !important;
    color: #000;
  }

  .warehouse-table .ant-table table {
    border-collapse: collapse !important;
    margin-top: 15px;
  }

  .warehouse-table .ant-table-tbody>tr>td,
  .warehouse-table .ant-statistic-content-value,
  .warehouse-table .ant-table-thead>tr>th {
    font-size: 12px !important;
    padding: 4px;
    font-weight: 400;
  }

  .warehouse-table .ant-table-thead>tr>th {
    position: static;
    background-color: #a4a4a4 !important;
    color: black;
    font-weight: 600;
    text-transform: capitalize;
    padding: 6px 10px !important;
    border-bottom: 1px solid var(--cardbkgcolor) !important;
  }

  .warehouse-table .ant-table-tbody>tr>td {
    background-color: initial;
    height: 40px;
    padding: 2px 10px;
    border-bottom: none;
  }

  .warehouse-table .ant-table-tbody>tr>td:not(.ant-table-expanded-row td),
  .red-ho-bg td,
  .warehouse-table .ant-table-thead>tr {
    border-bottom: 1px solid transparent !important;
  }

  .warehouse-table .ant-table-expanded-row td {
    color: rgb(122, 122, 122) !important;
  }

  .warehouse-table .ant-table-tbody>tr>td:not(.ant-table-expanded-row td),
  .warehouse-table .ant-statistic-content-value,
  .warehouse-table .red .ant-statistic-content-value,
  .warehouse-table .red,
  .red-ho-bg td,
  .warehouse-table .red-ho,
  .warehouse-table .amber {
    color: black !important;
  }

  .warehouse-table .ant-statistic-content-value {
    padding: 0;
  }

  .warehouse-table .ant-statistic-content {
    font-size: inherit;
  }

  .warehouse-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    content: none;
  }

  .warehouse-table .ant-table-row-expand-icon {
    transform: scale(0.5) !important;
  }

  .warehouse-screen .ant-checkbox {
    transform: scale(1) !important;
  }
}


@media screen and (min-width: 1601px) {
  .warehouse-switch {
    transform: scale(2.5);
    margin-left: 2.5vw !important;
  }
}

@media screen and (min-width: 768px) {
  .warehouse-switch {
    transform: scale(1.4);
    margin-left: 2vw !important;
  }
}

@media screen and (max-width: 767px) {
  .warehouse-screen .ant-checkbox {
    transform: scale(1) !important;
    margin-right: 14px;
    margin-left: 3px;
  }
  
}
