.custom-button-left.ant-btn {
  margin-left: auto;
  display: block;
  margin-top: 24px;
}

.user-action {
  display: flex;
  justify-content: flex-end;
  max-width: 60px;
  margin: auto;
}

.gridContainer {
  margin-bottom: 10px;
}

.titleColor {
  color: var(--primaryblue);
  font-weight: 600;
  font-size: 13px;
  min-width: 110px;
}
.gridData {
  font-size: 13px;
}

.action-column .geturl-icon {
  height: 16px;
}

.action-column .key-icon {
  height: 14px;
}

.copy-icon {
  margin-top: -2px;
}

@media screen and (min-width: 1599px) {
  .manage-table .ant-table {
    max-height: calc(var(--content-height) - 280px);
  }
}


@media screen and (max-width: 1600px) and (min-width: 993px) {
  .manage-table .ant-table {
    max-height: calc(var(--content-height) - 360px);
  }
}

@media print {
  body, html {
    min-height: 20vh;
    height: 10%;
  }
  
  .main-layout .ant-layout-content {
    display: none;
  }

  .print-mcq .ant-modal-close {
    display: none;
  }

  .print-mcq.ant-modal-centered {
    text-align: left;
  }
  
  .ant-modal-mask {
    position: static;
    display: none;
  }

  .print-mcq.ant-modal-wrap {
    position: relative ;
  }

  .add-mcq {
    width: 100%;
    padding-top: 30px;
  }
  
  .print-mcq .ant-modal-content {
    box-shadow: none;
    break-after: always;
  }

  .print-mcq .custom-modal .ant-modal-header , .print-mcq .custom-modal .ant-modal-body {
    background-color: transparent;
  }

  .print-mcq .custom-modal .ant-modal-header {
    margin-left: 24px;
    padding-left: 0;
  }
}