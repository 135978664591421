.fc-table .ant-table {
  max-height: calc(var(--content-height) - 162px);
}

.custom-table.multi-head.dod-multihead .ant-table-thead>tr:nth-child(2)>th {
  position: sticky;
  top: 48px;
}

.config-img img {
  width: 100%;
  height: auto;
}

.fcst-col>.ant-space {
  width: 80%;
}

.fcst-col>.ant-space>.ant-space-item:first-child {
  width: 24px !important;
}

.data-row .ant-input-number-sm {
  width: 30px !important;
}

.cargo-summary {
  background-color: var(--primaryblue);
  color: white;
  border-radius: 10px;
  height: 150px;
  overflow-y: auto;
}

.cargo-summary:before {
  content: '';
  position: absolute;
  background-color: var(--primaryblue);
  width: 80%;
  bottom: 0px;
  height: 10px;
  left: 13px;
}

.cargo-summary p {
  padding: 0 10px;
  font-size: 11px;
  margin-bottom: 0.5rem !important;
}

.cargo-summary p:first-child {
  padding: 10px 10px;
  border-bottom: 1px solid var(--redText);
  position: sticky;
  top: 0;
  background-color: var(--primaryblue);
}

.cargo-summary p span:nth-child(2) {
  float: right;
}

.dib-w90 {
  width: 90px;
  display: inline-block;
}

.rem_posn,
.rem-pos-title {
  font-size: 12px;
  font-weight: 500;
}

.load-card .info-title:not(.sub-head .info-title) {
  min-height: 47px;
  display: inline-block;
}

.cc-load .ant-input-number-handler-wrap,
.rem_posn .ant-input-number-handler-wrap {
  display: none;
}

.cc-load .ant-input-number-sm input {
  padding: 0 5px;
}

.custom-table.multi-head.fc-table .psn {
  min-width: 27px;
}

.dis-blue.ant-input-number-disabled {
  background-color: var(--primaryblue) !important;
  color: white
}

.dis-grey.ant-input-number-disabled {
  background-color: #b1b1b1 !important;
  color: white;
}

.dis-red.ant-input-number-disabled {
  background-color: var(--redText) !important;
  color: white;
}

.config-placeholder {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dotted lightgray;
  font-size: 13px;
}

.info-card-2.ant-card {
  border-color: var(--primaryblue);
  border-radius: 10px;
  background-color: var(--primaryblue);
  color: #fff;
}

.info-card-2 .ant-divider {
  border-color: var(--success);
  border-width: 3px;
}

.clip.ant-btn {
  width: 15px;
  height: 20px;
  min-width: 10px;
}

.clip .anticon {
  transform: rotate(317deg);
}

.table-pr-0 table {
  padding-right: 0;
}

.place-end {
  place-content: end;
  display: grid !important;
}

.light-checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: var(--primaryblue);
}

.light-checkbox .ant-checkbox-checked:not(.ant-checkbox-disabled.ant-checkbox-checked) .ant-checkbox-inner {
  background-color: #fff !important;
}

.disabled-bg-none.ant-btn.ant-btn-background-ghost[disabled] {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff;
}

.load-plan .info-card.cc-card.ant-card .info-title {
  font-size: 14px;
  color: #AAAAAA;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
}

.load-plan .info-card.cc-card.ant-card .info-data {
  font-size: 17px;
  color: #FFFFFF;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
}

.load-plan .info-card.ant-card {
  background-color: rgba(9, 43, 74, 1);
}

.w-40 {
  width: 40px !important;
}

.load-plan .ant-input-number-sm {
  min-height: 25px !important;
  height: 25px;
}

.bottom-0 {
  position: absolute;
  bottom: 0;
}

.load-plan .ant-modal-body {
  overflow: auto;
  height: 80vh;
}

.load-plan .ant-modal-header {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.load-plan .ant-modal-footer {
  background-color: #f2f2f2;
  justify-content: flex-end;
}

.border-dark {
  border: 2px solid #000;
}

.curved-card .ant-card-head {
  border-radius: var(--modalborderradius);
  border-bottom: none;
}

.curved-card {
  border-radius: var(--modalborderradius) !important;
}

.curved-card .ant-card-head {
  border-radius: var(--modalborderradius) var(--modalborderradius) 0 0 !important;
}

.curved-card .ant-card-body {
  padding: 0.5rem !important;
}

.custom-padding .ant-card-head-title {
  padding-bottom: 0;
}

.custom-padding .ant-card-body {
  padding-top: .25rem !important;
}

.cargo-cap-card .flight-ctrl .info-title {
  padding-bottom: 0;
}

.recd-ht {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 3px;
}


.conf-img {
  max-height: 148px;
  width: auto;
}

.load-plan .ant-input-number-disabled {
  color: rgb(0 0 0 / 55%) !important;
  background-color: #d1d1d1 !important;
}


.bg-grey, .bg-red, .bg-green, .bg-amber, .bg-white {
  height: 100%;
  text-align: center;
  cursor: pointer;
  padding: 4px;
}

.bg-grey {
  background-color: #d1d1d1 !important;
  color: white;
  font-weight: 600;
}

.bg-red {
  background-color: var(--redText);
  color: white;
  font-weight: 600;
}

.bg-green {
  background-color: green;
  color: white;
  font-weight: 600;
}

.bg-amber {
  background-color: #fdc43f;
  color: white;
  font-weight: 600;
}

.bg-white {
  background-color: white;
  color: #666666;
  font-weight: 600;
}

.custom-table .ant-table-thead>tr>th.main-head, .custom-table .ant-table-thead>tr>th[colspan].main-head {
  background-color: var(--primaryblue);
  border-bottom: 4px solid var(--redText);
  color: lightgrey;
  font-weight: 800;
  line-height: 2;
}
/* .custom-table .ant-table-thead > tr > th.main-head, .custom-table .ant-table-thead > tr > th[colspan].main-head::before, */
 .custom-table .ant-table-thead > tr > th.main-head:not(th.main-head:last-child):before
/* .ant-table-thead > tr > th::before  */
{
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 1.6em;
  background-color: rgba(174, 174, 174, 0.7);
  transform: translateY(-50%);
  transition: background-color .3s;
  content: "";
}


@media screen and (max-width: 1200px) {
  .load-card>.ant-col:nth-last-child(-n+4) {
    border-top: 1px solid #d3d3d338;
    padding-top: 12px;
  }
}

@media screen and (max-width: 768px) {
  .load-card>.ant-col:nth-last-child(-n+5) {
    border-top: 1px solid #d3d3d338;
    padding-top: 12px;
  }
}

@media screen and (max-width: 570px) {
  .load-card>.ant-col {
    border-top: 1px solid #d3d3d338;
    padding-top: 24px;
  }

  .info-title:not(.sub-head .info-title) {
    min-height: auto;
  }
}

@media (min-width: 1600px) {
  .load-plan .info-card.cc-card.ant-card .info-title {
    font-size: 18px;
  }

  .load-plan .info-card.cc-card.ant-card .info-data {
    font-size: 22px;
  }

  .load-card .info-title:not(.sub-head .info-title) {
    min-height: 57px;
  }
}

/* Builp Plan */
.left {
  background-color: var(--primeblue_op);
  border-radius: 15px;
  border: none;
  min-height: 61px;
  height: 108px;
}

.WB-tiles {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
}

.WB-tiles1 {
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
  border-radius: 10px;
  min-height: 75px;
}

.WB-tiles .spl {
  display: flex;
  /* height: 100%;
  padding-bottom: 3px; */
  /* border: 1px solid; */
  /* border-radius: 10px; */
  /* width: 58%;
  min-height: 75px; */
  /* min-height: 75px; */
}

.modal-icon svg{
  color: rgba(22, 29, 49, 1)
}

.ant-btn.modal-btn{
  border: none;
  box-shadow: none;
}

.checklist-cols{
  min-width: 100px;
  white-space: pre-line !important;
  word-wrap: break-word !important;
  max-width: 120px;
  line-height: 1.4;
}

.title-overflow{
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 32px;
}

th.ant-table-cell.checklist-cols-spl {
  background: #f2f2f2de !important; 
  max-width: 27px;
  white-space: pre-line !important;
  word-wrap: break-word;
}

.checklist-cols-1{
  max-width: 27px;
  white-space: pre-line !important;
  word-wrap: break-word;
  text-align: center !important;
}

.est-col {
  min-width: 63px;
}

.noride-cols{
  margin-top: 20px;
}

.custom-formitem .ant-form-item-label>label {
  height: auto;
}

.dod-card .custom-table .ant-table {
  max-height: 628px;
}

/* .test {
  display: inline-block;
  margin-bottom: 38px;
} */

th.checklist-cols-1 {
  vertical-align: middle;
}

.build-plan{
  color: var(--primaryblue);
  background: #f2f2f2;
  border-radius: 5px !important;
}

.ant-btn.build-plan:focus, .ant-btn.build-plan:hover {
  color: white;
  background: var(--primaryblue) ;
}
.div-icons{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CsIcon{
  font-size: 150%;
  padding-left: 6px;
}

.options {
  display: flex;
  align-items: flex-end;
}

.feedback{
  margin-left: 10px;
}

.modal-card{
  background: rgb(38 45 63) !important;
  border-radius: 10px !important;

}

.card-text{
  color: #929292 !important;
}

.modal-row{
  margin: 30px 15px;
}

.modal-row1{
  margin: 30px 20px;
  padding: 14px;
}

.text-info{
  margin-top: 18px;
  color: white;
  font-size: 11px;
  display: flex !important;
}

.opti-input{
  padding: 23px;
  border: 1px solid var(--tb-border);
  border-radius: 10px;
  background: var(--tableheader);
}

.config-row{
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  justify-content: space-between;
}

.right-section{
  margin-top: 10px !important;
  margin: 5px;
}

.opti-row {
  margin-left: 4px !important;
  margin-right: 0 !important;
  width: 100%;
}

.opti-input.last {
  flex-direction: column !important;
}

.info-item{
  display: flex;
  transform: translate(240px, -22px);
}

.info-text{
  margin-left: 10px;
}

.modal-checkbox{
  margin: 4px !important;
}

.modal-submit{
  background: rgb(38 45 63);
  border-radius: 4px;
  border: none;
  color: rgb(255 255 255);
  padding: 5px;
  margin-left: 20px;
}

.modal-submit1{
  background: rgb(38 45 63);
  border-radius: 4px;
  border: none;
  color: rgb(255 255 255);
  padding: 5px 40px;
  margin-left: 40px;
  margin-top: 19px;
  cursor: pointer;
}

.submit {
  margin-left: 0px !important;
}

.noride-btn{
  margin-top: 30px;
}

.noride-submit{
  width: 100%;
  display: flex;
  justify-content: center;
}

.milestone-submit{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ldd-ala-section{
  justify-content: space-between;
  width: 100%;
  margin-top: -30px !important;
}

.audit-quiz{
  margin: 30px;
  margin-top: 48px;
}

.upload-area{
  transform: translate(16px, -40px);
}

.above-table{
  display: flex;
  justify-content: space-between;
}

.no-ride{
  padding: 8px;
  width: 94%;
  margin: auto;
}

.toggle-view{
  display: flex;
  /* transform: translate(-95px, 0px); */
}

.ant-radio-button-wrapper .radio-change{
  border: none;

}

.switch {
  display: flex !important;
}

.goal{
  font-weight: 600;
}

/* .custom-radio-group .ant-radio-button-checked{
  background: var(--primaryblue);
  border-radius: inherit;
} */
.custom-radio-group .ant-radio-button-checked + span{
  color: rgb(255 6 40);
}

.custom-radio-group .ant-radio-button:not(.ant-radio-button-checked)+ span:hover{
  color: var(--primaryblue);
}

/* .custom-radio-group .ant-space-item:first-child .ant-radio-button-wrapper{
  border-radius: 10px 0 0 10px;
}

.custom-radio-group .ant-space-item:last-child .ant-radio-button-wrapper{
  border-radius: 0 10px 10px 0;
} */

.ant-radio-button-wrapper:first-child, .ant-radio-button-wrapper:last-child{
  border-radius: 50px;
}
.custom-radio-group .ant-space-item .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .custom-radio-group .ant-radio-button-wrapper{
  border-radius: 50px;
}

.custom-radio-group .ant-radio-button-wrapper:first-child:last-child{
  border-radius: 50px;
}

.custom-radio-group {
  display: inline-block; /* Ensure the container takes only the width of its content */
  border-radius: 50px;   /* Add desired border radius */
  padding: 5px;           /* Add some padding for spacing */
  background: white;
}


.radio-change.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
.radio-change.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--primaryblue);
  border: none;
  border-radius: 50px;
}

/* .custom-table.w-100.checklist-table1 tr:first-child td {
  background-color: #ffd460a6;
} */

.boxes{
  width: 95px;
  height: 90px;
  background: rgb(214 220 228);
  border: 1px solid black;
  border-left: 0;
  /* margin-top: 10px; */
}
.boxes764{
  width: 130px;
  height: 90px;
  background: rgb(214 220 228);
  border: 1px solid black;
}
.boxes764{
  border-left: 0;
}
.bl-1 {
  border-left: 1px solid black !important;
}

.boxes1{
  width: 70px;
  height: 120px;
  background: rgb(214 220 228);
  margin-top: 10px;
  border: 1px solid black;
}

.boxes2{
  width: 70px;
  height: 120px;
  background: rgb(191 191 191);
  margin-top: 10px;
  border: 1px solid black;
  border-left: 0;
}

.radio-change.ant-radio-button-wrapper{
  height: 25px;
  line-height: 25px; 
  border-radius: 50px;
}

.custom-radio-group .ant-radio-button-wrapper:first-child{
  border-left: 0;
}

.custom-radio-group .ant-radio-button-wrapper{
  border: 0;
}

.custom-icon{
  margin-top: 2px;
}

.grey-col{
  padding-right: 0px !important;
}

.parent-div-764{
  /* width: 1858px; */
  max-width: 1615px;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  margin-top: 42px;
  position: relative;
}
.parent-div-763{
  max-width: 1285px;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  margin-top: 0px;
}
.parent-div-333{
  /* width: 1858px; */
  max-width: 1415px;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  margin-top: 42px;
  position: relative;
}
.parent-div-332{
  max-width: 1221px;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  margin-top: 0px;
}
.parent-div-339{
  max-width: 1351px;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
}
.parent-div{
  max-width: 1545px;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  /* margin-top: 0px; */
}


.top-row-332{
  display: flex;
  /* flex: inherit; */
  margin-top: 20px;
  padding: 12px 0;
  /* margin-left: 466px; */
  width: 1221px;
  position: relative;
  justify-content: flex-end;
  right: 175px;
}

.top-row{
  margin-top: 50px;
  right: 104px;
  width: 1545px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  position: relative;
}

.top-row-339{
  display: flex;
  /* flex: inherit; */
  margin-top: 50px;
  padding: 12px 0;
  /* margin-left: 364px; */
  width: 1351px;
  justify-content: flex-end;
  /* margin-right: 106px; */
  position: relative;
  right: 104px;
}

.top-row-333{
  display: flex;
  /* flex: inherit; */
  margin-top: 20px;
  padding: 12px 0;
  width: 1415px;
  /* margin-left: 421px; */
  justify-content: flex-end;
  /* width: 80%; */
  position: relative;
  right: 104px;
}

.top-row-763{
  display: flex;
  /* flex: inherit; */
  margin-top: 50px;
  padding: 12px 0px;
  /* margin-right: 39px; */
  position: relative;
  width: 1285px;
  justify-content: flex-end;
  right: 104px;
}

.top-row-764{
  display: flex;
  /* flex: inherit; */
  margin-top: 20px;
  padding: 12px 0;
  /* margin-left: 857px; */
  width: 1615px;
  justify-content: flex-end;
  position: relative;
  right: 104px;
}

.ant-btn.clear-bins{
  color: rgba(0,0,0,.85);
  border: 1px solid #d9d9d9;
  height: 85%;
  margin: 10px 12px;
}
.ant-btn.clear-bins-332{
  color: rgba(0,0,0,.85);
  border: 1px solid #d9d9d9;
  height: 85%;
  margin: 10px 10px;
  text-wrap: wrap;
  width: 92%;
}
.ant-btn.clear-bins-359{
  color: rgba(0,0,0,.85);
  border: 1px solid #d9d9d9;
  height: 85%;
  margin: 10px 2px;
  text-wrap: wrap;
  width: 80%;
}

.ant-btn.clear-bins:not(:disabled):hover{
  border: inherit;
  color: inherit;
  transition: 0s;
  box-shadow: 0px 0px 2px grey;
}

.span-text{
  font-size: 1.1rem;
  line-height: 19px;
  /* display: inline-block;
  margin-top: 17px; */
  text-wrap: wrap;
  color: #6b6f74;
  font-weight: 600;
}

.box-line{
  background: white;
  height: 20px;
}
.box-line1{
  background: white;
  height: 20px;
  border-bottom: 1px solid black;
}

.split-box{
  min-width: 75px;
  height: 30px;
  background: rgb(214 220 228);
  /* border: 1px solid black; */
  /* margin-top: 10px; */
  /* border-bottom: none; */
  border-top: 1px solid;
}
.split-box1{
  min-width: 75px;
  height: 20px;
  background: rgb(214 220 228);
  /* border: 1px solid black; */
  /* border-bottom: none; */
}

.box-text{
  display: flex;
  justify-content: center;
}

.bottom-box{
  width: 65px;
  height: 60px; 
  background: rgb(214 220 228);
  border: 1px solid black;
  border-top: 0;
  border-left: 0;
}

.top-box{
  width: 65px;
  height: 60px; 
  background: rgb(214 220 228);
  border: 1px solid black;
  border-left: 0;
  margin-top: 10px;
}

.box-line-bottom{
  background: white;
  height: 20px;
  border-bottom: 1px solid black;
}

.middle-row{
  display: flex;
  margin: auto;
  /* margin-top: 20px; */
}

.bottom-row{
  display: flex;
  margin: auto;
}

.estimates {
  margin-top: 20px;
}

.plane-back{
  margin-top: -110px;
}

.plane-front{
  margin-top: 10px;
}

.empty-box{
  width: 1000px;
  visibility: hidden;
}

.modal-title{
  display: flex;
  /* background: rgb(7 58 112); */
  padding: 10px;
  padding-bottom: 10px;
  margin: none;
  justify-content: space-between;
}

/* .modal-title{
  display: flex;
  background: rgb(7 58 112);
  padding: 10px;
  margin: none;
  justify-content: space-between;
} */

.center-ac{
  display: flex;
  flex-direction: column;
}

.ac-name {
  font-size: clamp(24px, 3vw, 34px);
  line-height: normal;
}

.center-title{
  color: #575757;
  /* margin: auto; */
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2rem;
}

.custom-modal .ant-modal-header {
  padding: 24px;
}

.table-card{
  display: flex;
  justify-content: flex-end;
  max-width: 1400px;
  margin-top: -172px;
  /* margin-left: 120px; */
}

.hint-form{
  margin-top: 5px;
}

.tile-header {
  text-align: center;
  font-size: 95%;
  font-weight: 600;
  background: rgb(191 191 191);
  border-top-left-radius: inherit;
  border: 1px solid #4f4a4a;
  border-top-right-radius: inherit;
  padding: 10px 4px;
  text-wrap: nowrap 
}

.tile-header1 {
  background: rgb(172 186 202);
}

.tile-header2 {
  text-align: center;
  font-weight: 600;
  background: rgb(191 191 191);
  border: 1px solid;
  border-top: none;
  border-left: none;
}

.spl .ant-space-item:last-child .tile-header2 {
  border-right: none;
}
.tile-data{
  text-align: center;
  background: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 4px;
  border: 1px solid #4f4a4a;
  border-top: none;
  min-height: 37px;
}

p.tile-data{
  margin-bottom: 0;
}

.tile-data1 .ant-input-number{
  min-height: auto !important;
}

.tile-data1{
  text-align: center;
  background: white;
  flex: auto;
  display: flex;
  padding: 4px;
}

.spl .ant-space-item:first-child .tile-data1{
  border-bottom-left-radius: 10px;
}

.spl .ant-space-item:last-child .tile-data1{
  border-bottom-right-radius: 10px;
}

.pax-count{
  display: flex;
  flex-direction: column;
}

.title-tile{
  text-align: center;
  /* margin-left: 80px; */
  display: block;
  font-size: 95%;
  font-weight: 600;
  background: rgb(191 191 191);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: auto;
  padding: 2px;
}

.spl{
  display: flex;
  border-top: 1px solid;
}

.pax-count .w-40 .special{
  border-bottom: 1px solid;
}

.WB-margin {
  margin-top: 24px;
  margin-bottom: 24px;
}

.grey-bkg {
  background-color: rgb(191 191 191);
}

.blue-bkg {
  background-color: rgb(172 186 202);
}

.new-card{
  background: rgb(191 191 191);
  margin-top: -8px;
  margin-left: -24px;
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.new-card1{
  background: rgb(172 186 202);
  margin-top: -24px;
  margin-right: -24px;
  border-left: 1px solid;
  /* margin-right: 10px; */
  margin-left: -10px;
  border-bottom: 1px solid;
  border-top: 1px solid;
  /* min-height: 130px; */
  height: 135px;
}

.dl-logo{

  margin-left: 2rem;
}

.logo{
  margin-right: 2rem;
}

.logo img{
  margin-left: auto;
  display: block;
  width: 80px;
}

 .save{
  transform: translate(990px, 0);
  background-color: var(--primaryblue) !important;
  border: none;
  color: white !important;
  border-radius: 4px;
  font-size: 1rem;
  min-width: 74px;
  height: var(--inputheight);
}


.outer-container{
  margin-top: calc(var(--bodymargin) * -1 );
  display: flex;
  width: 100%;
  padding-top: 25px;
}

.custom-modal .ant-modal-close-x{
  /* color: white; */
  font-size: 1rem;
}

.spl .ant-space-item  {
  flex: 1;
}


.diff-tile{
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px;
}

.uldicon{
  height: 1.3rem;
}

.specialP{
  margin: 0;
  margin-top: 10px;
}

.custom-button.t{
  margin-left: auto;
  display: block;
}

.custom-button.close{
  margin-bottom: 14px;
}

/* .custom-button.run {
  margin-left: auto;
} */

.disable-col > .top-box,
.disable-col > .box-text,
.disable-col > .box-line-bottom,
.disable-col > .bottom-box,
.disable-col > .boxes,
.disable-col > .split-box,
.disable-col > .split-box1,
.disable-col > .boxes764{
  background-color: lightgrey;
  pointer-events: none;
}

.red-bkg, .red-bkg.ant-input-number-disabled {
  background-color: red !important;
  height: 100%;
  min-height: 100% !important;
}

/* .red-bkg.ant-input-number-disabled input {
  height: 100% !important;
} */

.tile-data input {
  text-align: center;
}
.tile-data2 input {
  text-align: center;
}
.tile-data1 input {
  text-align: center;
}
.tile-data3 input {
  text-align: center;
}

.box-line .ant-input-number-input,
.box-line-bottom .ant-input-number-input {
  text-align: center;
  
}

.box-line-bottom .ant-input-number, .box-line-bottom .ant-input-number-sm input {
  min-height: 20px !important;
  height: 20px !important;
}

.box-line .ant-input-number,
.box-line-bottom .ant-input-number {
  border-radius: 0 !important;
  
}

.box-line .red-bkg .ant-input-number-borderless,
.box-line-bottom .red-bkg .ant-input-number-input{
  color: white;
  font-weight: 500;
}

.container-row{
  width: 70%;
  margin: auto;
  padding-top: 10px;
}

.tail{
  height: 250px;
}


.custom-button.bp-button {
  min-width: 130px;
}

.upload-name .ant-upload-list-picture .ant-upload-list-item-name, .upload-name .ant-upload-list-text .ant-upload-list-item-name {
  max-width: 8ch;
}


.dod-card{
  min-height: calc(100vh - 167px);
}

.color-cols {
  text-align: center;
}

.custom-table .ant-table-tbody>tr>td.color-cols{
  padding: 0 1px;
}

th.ant-table-cell.checklist-cols.color-cols{
  text-align: center;
  vertical-align: top;
}

th.th-top {
  vertical-align: top;
}

.plus-btn{
  margin-left: auto;
}

.checklist-bp-btn.ant-btn {
line-height: 1;
}

.feedback-btn.ant-btn>.anticon+span, .feedback-btn.ant-btn>span+.anticon{
  font-size: 13px;
  color: var(--label);
  font-weight: 600;
}

.feedback-btn.ant-btn {
  font-size: 16px;
}

.quiz-modal .ant-modal-footer, .audit-modal .ant-modal-footer {
  background-color: var(--cardbkgcolor);
}

.custom-modal.quiz-modal .ant-modal-body {
  max-height: calc(100vh - 40px - 60px);
  overflow: auto;
}

.custom-modal.audit-modal .ant-modal-body {
  max-height: calc(100vh - 40px - 300px);
  overflow: auto;
}
.uploaded-img {
  max-width: 60px;
  background-color: white;
  border-radius: 4px;
  padding: 10px 20px;
  box-sizing: content-box;
}

.ant-btn.uploaded-img-btn {
  height: auto;
}

.questions {
  margin: 8px 0;
  font-size: 13px;
  line-height: 1.5;
  color: #666;
}

.other-popup.ant-select-dropdown {
  max-width: 200px;
  width: auto !important;
}

.mcq-popup .ant-select-item, .mcq-popup .ant-select-item-empty {
  font-size: 12px;
}

.info-section{
  display: flex;
  /* margin-left: 100px; */
  margin-top: 20px;
  padding: 5px;
  margin-left: 7px;
}

.add-flight-details{
  display: flex !important;
  justify-content: space-between;
}

.uld-data{
  border-top: 1px solid #f0f0f0;
  padding-top: 6px;
  margin-top: 20px;
}

.data-placeholder{
  color: #6b6f74;
}

.wb-tables{
  padding: 20px;
  /* gap: 100px; */
}

.email-table {
  height: 0;
  display: none;
}

.title-text {
  margin-bottom: 20px;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  border-top: 1px solid lightgray;
  padding-top: 34px;
}

#config-table .table-row-danger td {
  background-color: #ff4d4f;
}

#config-table .table-row-warning td {
  background-color: #ffc53d;
}

#config-table .table-row-success td {
  background-color: #52c41a;
}

.bg-danger {
  background-color: #ff4d4f;
}

@media screen and (max-width: 1599px) and (min-width: 1200px) {
  .new-card {
    justify-content: center;
    position: relative;
  }

  .new-card:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 2.1%;
    background-color: rgb(191 191 191);
  }

  .new-card:after {
    position: absolute;
    content: '';
    top: 0px;
    right: 0;
    bottom: 0px;
    height: 100%;
    width: 2.1%;
    background-color: rgb(172 186 202);
  }
}

@media screen and (max-width: 1599px) {
  .new-card {
    background: transparent;
  }
}

@media screen and (max-width: 991px) {
  .new-card {
    margin-right: -24px;
  }

  .new-card1{
    max-height: 131px;
    border-top: none;
    margin-top: 0;
    margin-left: -24px;
  }
  
  .container-row {
    width: 90%;
    padding-top: 40px;
  }
}

@media screen and (min-width: 1600px) {
  .ant-col.type-col {
    flex: 1 0 8%;
  }
}

.ac-alert {
  display: flex;
  justify-content: center;
}

.c763{
  margin-top: 10px;
}

@media screen and (max-width: 1700px) {
  .top-row-764{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
  }
}

@media screen and (max-width: 1700px) {
  .top-row{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
    position: relative;
    right: 234px;
  }
}

@media screen and (max-width: 1700px) {
  .top-row-333{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
    position: relative;
    right: 84px;
  }
}

@media screen and (max-width: 1700px) {
  .top-row-332{
    webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -ms-transform:scale(0.9);
    transform:scale(0.9);
    position: relative;
    right: 145px;
  }
}

@media screen and (max-width: 1700px) {
  .top-row-339{
    webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -ms-transform:scale(0.9);
    transform:scale(0.9);
    position: relative;
    right: 95px;
  }
}

@media screen and (max-width: 1700px) {
  .top-row-763{
    webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -ms-transform:scale(0.9);
    transform:scale(0.9);
    position: relative;
    right: 95px;
  }
}

@media screen and (max-width: 1700px) {
  .middle-row{
    webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -ms-transform:scale(0.9);
    transform:scale(0.9);
  }
}

@media screen and (max-width: 1700px) {
  .a333{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
  }
}

@media screen and (max-width: 1700px) {
  .b764{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
  }
}
@media screen and (max-width: 1595px) {
  .b764{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
    position: relative;
    right: 150px;
  }
}
@media screen and (max-width: 1595px) {
  .top-row-764{
    webkit-transform:scale(0.8);
    -moz-transform:scale(0.8);
    -ms-transform:scale(0.8);
    transform:scale(0.8);
    position: relative;
    right: 233px;
  }
}

.add-flight-info {
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-end;
}

.tag-class {
  justify-content: center;
}

.dropdown-options{
  min-width: 250px !important;
}

/* .shrink {
  -webkit-transform:scale(0.8);
  -moz-transform:scale(0.8);
  -ms-transform:scale(0.8);
  transform:scale(0.8);
} */

.awb-switch .ant-switch-checked {
  background-color: #0b1227 !important;
}

.awb-switch .ant-switch {
  background-color: var(--redText);
  font-weight: 600;
}
