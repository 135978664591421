.rep-sub-title {
  font-size: 14px;
  font-weight: 500;
}

.module-radio span.ant-radio+span,
.range-radio span.ant-radio+span {
  font-size: 13px;
  font-weight: 500;
}

.download-btn.custom-button:not(:disabled) {
  min-width: 180px;
}

.red-progressbar.ant-progress-line {
  font-size: 0;
  position: absolute;
  left: 0;
  bottom: 0;
}

.red-progressbar .ant-progress-bg {
  background-color: #d9363e !important;
}

.reports-weekpicker .ant-picker-week-panel-row-selected td.ant-picker-cell-disabled,
.reports-weekpicker .ant-picker-week-panel-row-selected td.ant-picker-cell:not(.ant-picker-cell.ant-picker-cell-in-view),
.reports-weekpicker .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-disabled,
.reports-weekpicker .ant-picker-week-panel-row-selected:hover td.ant-picker-cell:not(.ant-picker-cell.ant-picker-cell-in-view) {
  background-color: transparent;
  color: rgba(0, 0, 0, .25);
}

.reports-weekpicker .ant-picker-week-panel-row-selected td.ant-picker-cell.ant-picker-cell-in-view .ant-picker-cell-inner,
.reports-weekpicker .ant-picker-week-panel-row-selected:hover td.ant-picker-cell.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: white;
}

.reports-weekpicker .ant-picker-week-panel-row-selected td.ant-picker-cell .ant-picker-cell-inner,
.reports-weekpicker .ant-picker-week-panel-row-selected:hover td.ant-picker-cell .ant-picker-cell-inner,
.reports-weekpicker .ant-picker-week-panel-row-selected td.ant-picker-cell-disabled.ant-picker-cell-in-view .ant-picker-cell-inner,
.reports-weekpicker .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-disabled.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: rgba(0, 0, 0, .25);

}

@media screen and (max-width: 991px) {
  .module-radio {
    justify-content: flex-start;
    gap: 5px;
  }

  .module-radio>label {
    flex: 0 0 180px;
  }
}