.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid transparent;
  border-top-color: var(--primaryblue);
  border-radius: 50%;
}

.breakdown-control {
  --uldtableheight: calc(var(--content-height) - 255px);
}

.custom-table.breakdown-uld-table .ant-table {
  max-height: var(--uldtableheight);
}

.custom-table.breakdown-awb-table .ant-table {
  max-height: calc((var(--uldtableheight)/2) - 2px);
}

.info-card.ant-card {
  background-color: var(--primaryblue);
  border-radius: 15px;
  padding: 0 24px;
  border: none;
  position: relative;
}

.info-card.ant-card .ant-card-body::before,
.info-card.ant-card .ant-card-body::after {
  content: none;
}

.info-card.ant-card .ant-card-head,
.info-card.ant-card .ant-card-body {
  color: #e2e2e2;
}

.info-card.ant-card .ant-card-head {
  border-bottom: 1px solid #939393;
  padding: 0;
}

.info-card.ant-card .ant-card-body {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.info-card.ant-card .info-data {
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1;
}

.info-card.ant-card .info-title {
  color: #939393;
  font-weight: 700;
}

.info-card.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
  padding-bottom: 0;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}

.title-wrapper h1 {
  font-size: 14px;
  margin-bottom: 0;
  color: #e2e2e2;
}

.disabled-info-card.info-card.ant-card {
  background-color: #ececec;
}

.disabled-info-card.info-card.ant-card .ant-card-head,
.disabled-info-card.info-card.ant-card .ant-card-body,
.disabled-info-card.info-card.ant-card .info-title,
.disabled-info-card.info-card.ant-card .title-wrapper h1 {
  color: var(--primaryblue);
}

.mark-completed {
  width: 14px;
  height: max-content;
}

.pointer {
  cursor: pointer;
}

.breakdown-filter .ant-checkbox+span {
  padding-right: 0;
}

.filterht {
  min-height: 50px;
}

/* DO nOT DELETE */
/* .breakdown-filter [class*="col"]:not(:last-child) {
  padding-right: 0 !important;
} */

.breakdown-filter .ant-input,
.breakdown-filter .ant-input-number,
.breakdown-filter .ant-picker,
.breakdown-filter .ant-input-search .ant-input-affix-wrapper,
.breakdown-filter .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: var(--tblfs) !important;
}

.breakdown-filter .w-50px.ant-input-number,
.breakdown-filter .w-50px.ant-input-number-input {
  width: 50px !important;
  transform: scale(0.9);
  border-width: 1px !IMPORTANT;
}

.breakdown-filter .ant-input-number-input {
  height: 26px;
}

.breakdown-filter .ant-select-selection-item,
.breakdown-filter .ant-select:hover .ant-select-selection-item {
  margin-top: 2px;
}

.small-input {
  min-height: 20px !important;
  line-height: normal !important;
  padding: 0 6px;
}

.small-input.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 27px;
}

.small-input .ant-select-arrow {
  font-size: 10px;
}

.sort-select .ant-form-item-control {
  width: 50%;
}

.no-header-table.custom-table .ant-table-thead>tr>th {
  display: none;
}

.no-header-table.custom-table table {
  margin-top: -24px;
}

.no-data-table.custom-table .ant-table-tbody>tr>td {
  display: none;
}

.no-data-table.custom-table table {
  border-spacing: 0 !important;
  margin-top: 0;
}

.breakdown-scrollsync-outer {
  display: flex;
  flex-direction: column;
}

.breakdown-scrollsynctable-1,
.breakdown-scrollsynctable-2,
.breakdown-scrollsynctable-3 {
  width: 100%;
  margin: 0;
}

/* scrollbar hidden for header table */
.breakdown-scrollsynctable-1 {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.breakdown-scrollsynctable-1::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/* xx */

.breakdown-scrollsynctable-1::-webkit-scrollbar {
  height: 0;
}

.width150 {
  width: 150px;
}

.sticky-title {
  position: sticky;
  left: 0;
  top: 0px;
  z-index: 1;
  background-color: var(--cardbkgcolor);
  padding: 6px 0;
}

/* .with-table.awb-withtable>[class*="col-"] {
  padding-right: 0px !important;
} */
.br-slider.slick-slider {
  width: calc(100% - 100px);
  margin-bottom: 0;
  /* with arrows */
}

.br-slider .slick-track {
  margin: 0;
}

.bd-divider {
  width: calc(100% - 24px);
}

.info-skelecton.ant-skeleton-element {
  height: 95px;
  border-radius: 20px;
  width: calc(100% - 24px);
}

.info-skelecton.ant-skeleton-element>span {
  height: 100%;
}

.mauto {
  margin: auto;
}

/* INCORRECT LOC MODAL */
.incorrect-loc {
  font-weight: 500;
}

.incorrect-loc p {
  font-size: 1rem;
}

.incorrect-loc .ant-form-item-label>label {
  font-size: 1rem !important;
  font-weight: 500;
}

.uld-time .ant-select-selection-item,
.uld-time .ant-select:hover .ant-select-selection-item {
  margin-top: 0 !important;
}

.uld-time.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 6px !important;
}

.uld-time.ant-select:not(.ant-select-disabled, .ant-select-customize-input):hover .ant-select-selector:not(.ant-select-borderless .ant-select-selector) {
  border-width: 1px;
}

.uld-time .ant-select-selector {
  border-color: var(--tb-border) !important;
}

.uld-time {
  transform: scale(0.9);
}

.hidden-countdown {
  display: none;
}

.bc-slideitem {
  display: flex;
  align-items: center;
}

.sitem-left {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 100%;
  width: 20px;
}

.sitem-left>.anticon {
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sitem-left>.anticon:hover {
  background-color: rgba(0, 0, 0, 0.13);
  border-radius: 15px 0 0 15px;
}

.sitem-right {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 100%;
  width: 20px;
}

.sitem-right>.anticon {
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sitem-right>.anticon:hover {
  background-color: rgba(0, 0, 0, 0.13);
  border-radius: 0 15px 15px 0;
}

.sitem-selected .anticon {
  color: white;
}

.sitem-right.sitem-selected>.anticon:hover {
  background-color: rgba(247, 247, 247, 0.13);
  border-radius: 0 15px 15px 0;
}

.sitem-left.sitem-selected>.anticon:hover {
  background-color: rgba(247, 247, 247, 0.13);
  border-radius: 15px 0 0 15px;
}

.badge-circle {
  min-width: 22px;
  height: 22px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: normal;
  padding: 4px;
}

.badge-capsule {
  min-width: 22px;
  height: 22px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: normal;
  padding: 4px;
}

.badge-grey {
  background-color: rgb(196, 196, 196);
  color: black;
}

.badge-green {
  background-color: var(--success);
  color: white;
}

.badge-red {
  background-color: var(--danger);
  color: white;
}

.badge-yellow {
  background-color: #eaaa00;
  color: black;
}

.fliter-dd {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fliter-dd label.ant-checkbox-wrapper {
  color: inherit !important;
  font-size: 13px !important;
  font-weight: normal;
  padding: 0px 10px;
}

.fliter-dd label.ant-checkbox-wrapper:first-child {
  padding-top: 10px;
}

.filter-reset button {
  font-size: 13px;
}

.filter-reset {
  border-top: 1px solid #d9d9d9 !important;
}

.filter-search {
  margin: 10px 10px 0 10px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  width: calc(100% - 20px) !important;
}

.fliter-text-align {
  text-align: right;
}

.link-btn.ant-btn-link {
  color: var(--primaryblue) !important;
  font-weight: 500 !important;
}

.breakdown-uld-table .ant-empty-normal,
.awb-withtable .ant-empty-normal {
  margin: 20px 0;
}

.sort-ip {
  max-width: 200px;
}

.uld-tbl-filter {
  border-spacing: initial;
  max-height: 300px;
  overflow: auto;
}

.ant-table-filter-dropdown .uld-tbl-filter:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  left: 0;
  height: 10px;
  background-color: white;
  z-index: 11;
}

.ant-input-affix-wrapper.filter-search {
  position: sticky;
  top: 10px;
  background: white;
  z-index: 10;
  margin-top: 10px !important;
}

.filter-reset {
  position: sticky;
  bottom: 0;
  background: white;
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .custom-table.breakdown-uld-table .ant-table {
    max-height: calc(var(--content-height) - 292px);
  }
}

@media screen and (max-width: 767px) {
  .fliter-text-align {
    text-align: left;
  }
}