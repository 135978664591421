.ant-layout-header.header-outer {
  background: var(--primaryblue) !important;
  border-radius: 15px 15px 0 0;
  margin-right: var(--bodymargin);
  margin-left: var(--bodymargin);
  min-height: var(--headerheight);
  height: auto !important;
  padding: 0 !important;
  line-height: normal !important;
}

.header-container,
.header-first,
.header-last {
  height: 100%;
}

.header-first,
.header-userdetails {
  width: 310px;
  padding: 8px 10px;

}

.header-middle {
  background-color: var(--secondarygrey);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.company-title,
.dnt-sub-title,
.page-title {
  color: var(--greyText) !important;
}

.company-title {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1 !important;
  margin-bottom: 3px !important;
}

.dnt-sub-title {
  color: white !important;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 10px;
}

.page-title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  float: right;
}

.logo-img .ant-image-img {
  margin-left: -10px;
  margin-bottom: -18px;
}

.user-details {
  color: var(--greyText) !important;
  font-size: 9px;
}

.header-userdetails .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent !important;
  border: 1px solid var(--redText) !important;
  color: white;
  border-radius: 20px !important;
  font-size: 12px;
}

.header-userdetails .ant-select-arrow {
  color: white !important;
}


/* ========= sider ========= */

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.header-first {
  z-index: 100; /* overlay */
  background-color: var(--primaryblue);
  border-radius: 15px 0 0 0;
}

.change-password-icon {
  width: 18px;
  filter: invert(1);
  opacity: 0.8;
  background-color: #979797ad;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid grey;
  margin-left: 4px;
}

.change-password-text a {
  font-size: 10px;
  color: var(--redText);
  font-weight: 600;
}

.sidbar.ant-layout-sider {
  position: absolute !important;
  top: calc(var(--headerheight) + 24px + 1px);
  /* header height + margintop + 1px */
  left: 24px;
  bottom: 0;
  z-index: 100;
  padding-top: 12px;
  height: 100%;
}

.sidbar.ant-layout-sider .ant-typography {
  color: white;
}

.slider-menu {
  height: calc(100vh - 277px);
  overflow: auto;
}

.menu-text {
  position: absolute;
  color: var(--redText);
  font-size: 8px;
  bottom: -6px;
  z-index: 1;
  font-weight: 700;
  right: -5px;
  cursor: pointer;
}

.menu-title {
  color: white;
  text-transform: uppercase;
  padding: 2px 8px;
  font-weight: bold;
  border-bottom: 3px solid var(--redText);
  margin: 12px 10px;
  text-align: center;
}

li.sider-menu-item.ant-menu-item.ant-menu-item-only-child {
  min-height: 66px;
  text-align: left;
}

.sider-menu-item.ant-menu-item-selected {
  background-color: #7d838957 !important;
  border-right: 4px solid var(--redText) !important;
}

.user-details-outer {
  padding: 8px 10px;
}

.user-details {
  text-align: left;
}

.user-details>* {
  margin-bottom: 6px !important;
}

.username {
  color: white;
  font-weight: 300 !important;
  font-size: 16px;
}

.user-position {
  font-size: 14px;
}

.signout,
.signout:hover {
  color: white;
}

.signout .anticon-upload {
  font-size: 24px;
  transform: rotate(90deg);
}

.signout-text {
  font-size: 12px !important;
}

.weight-toggle .ant-switch {
  /* background-color: rgb(255 255 255 / 48%); */
  /* not visible on dev */
  background-color: #9e9e9e;
  margin: 0 12px;
  transform: scale(0.9);
}

.weight-toggle .ant-switch-checked {
  background-color: var(--redText);
}

.weight-toggle .ant-switch-small {
  margin-left: 12px;
}

.toggle-label {
  color: #e1e1e1;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.h-mt {
  margin-top: 24px;
}

/* if server is disconnected */
.dis-server {
  color: white;
  background-color: var(--danger);
  text-align: center;
  margin-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

/* if sess-server timeout */
.sess-server {
  color: white;
  background-color: var(--secondaryblue);
  text-align: center;
  margin-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.h-search-dis {
  top: 72px;
}

.h-dis-mt {
  margin-top: 48px;
}

.transparent-disabled-select.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector:not(.ant-select-borderless .ant-select-selector) {
  background: transparent !important;
}

/* xx */
@media screen and (max-width: 990px) {
  .header-middle {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0px;
  }

  .header-userdetails {
    position: absolute !important;
    top: calc(var(--headerheight) + 48px);
    width: calc(100% - 2 * var(--bodymargin));
    background-color: var(--primaryblue);
    border-radius: 15px;
    padding: 12px;
    justify-content: space-around;
  }

  .content {
    margin-top: 100px;
    min-height: calc(100vh - var(--headerheight) - calc(2* var(--bodymargin)) - 100px) !important;
  }
}

@media screen and (max-width: 767px) {
  .ant-layout-header.header-outer {
    margin-right: var(--bodymargin);
    margin-left: var(--bodymargin);
  }

  .sidbar.ant-layout-sider {
    left: var(--bodymargin);
  }

  .header-userdetails {
    width: calc(100% - 2 * var(--bodymargin));
  }

}

@media print {
  .header-userdetails .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    text-align: right;
    padding: 0;
    margin-left: 6px;
    margin-top: 50px;
  }

  .header-userdetails .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
    padding-right: 0;
  }

  .header-userdetails .ant-select {
    margin-left: auto;
  }

  .header-userdetails .ant-select-selector .ant-select-selection-item {
    font-size: 18px !important;
    font-weight: 500;
  }

 .header-first {
    padding-left: 0;
  }

  .header-outer {
    border-bottom: 2px solid firebrick;
  }

  .logo-img .ant-image-img {
    margin-bottom: -10px;
    margin-left: 0;
  }

  .page-title {
    float: none;
  }

  .sidbar.ant-layout-sider {
    display: none;
  }

  .ant-notification {
    display: none !important;
  }
}