
.haulout-dash .warehouse-table .ant-statistic-content {
  color: var(--tb-border);
  font-size: inherit;
}

.dr-haulout .ant-picker-input>input::placeholder,
.dr-haulout .ant-picker-input>input {
  font-size: 1.4vw !important;
}

.dr-haulout .ant-drawer-content-wrapper {
  height: auto !important;
  min-height: 22vw;
}

.red-ho {
  color: var(--danger) !important;
  border-color: transparent !important;
}

.amber-ho-bg td {
  color: var(--warning) !important;
  border-color: transparent !important;
  border-bottom: 0.2vw solid #464646 !important;
}

.brown-bu-bg td {
  color: var(--brown) !important;
  border-color: transparent !important;
  border-bottom: 0.2vw solid #464646 !important;
}

.red-ho-bg td {
  color: var(--danger) !important;
  border-color: transparent !important;
  border-bottom: 0.2vw solid #464646 !important;
}

.nested-awb {
  margin-right: -0.5vw;
}

.nested-prod {
  padding-right: 1.2vw;
}

.countdown-haul.countdown .ant-statistic-content-value {
  font-size: inherit;
} 